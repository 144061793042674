import SvgLogo from '@/assets/icon/CanlyLogoFull.svg';

type CanlyLogoWithLabelProps = {
  size?: 'sm' | 'md' | 'lg';
};

export function CanlyLogoWithLabel({ size = 'sm' }: CanlyLogoWithLabelProps) {
  const { width, height } = {
    sm: { width: '197.98px', height: '24px' },
    md: {
      width: '330px',
      height: '40px',
    },
    lg: { width: '495px', height: '60px' },
  }[size];

  return <SvgLogo width={width} height={height} viewBox="0 0 330 40" />;
}

'use client';

import { useEffect, useMemo } from 'react';
import { ErrorPage } from '@/components/views/Error';

type ErrorProps = {
  error: Error;
};

export default function Error({ error }: ErrorProps) {
  // nextjsのv15.1からforbidden.tsxなどが利用可能なので後々そちらに改修するとして、ひとまず現状は500エラーハンドリングに403エラー画面を追加して利用
  // https://nextjs.org/docs/app/api-reference/file-conventions/forbidden
  useEffect(() => {
    document.title = 'カンリー店舗集客 | Error Page';
  }, []);

  const errorInfo = useMemo(() => {
    switch (error.message) {
      case '403':
        return {
          code: 403,
          text: 'Forbidden',
          message: 'アクセスしようとしたページは表示できませんでした',
          subMessage:
            '権限が変更されているか、\n管理対象の店舗や会社などが変更された可能性があります。',
        };
      default:
        return null;
    }
  }, [error]);

  return errorInfo ? (
    <ErrorPage
      statusCode={errorInfo?.code || 500}
      statusText={errorInfo?.text || 'Internal Server Error'}
      message={
        errorInfo?.message || 'アクセスしようとしたページは表示できませんでした'
      }
      subMessage={
        errorInfo?.subMessage ||
        'サーバーエラーが発生しました。しばらくしてからもう一度お試しください。'
      }
    />
  ) : null;
}

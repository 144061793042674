'use client';

import { useEffect, useState } from 'react';
import {
  useDisclosure,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Flex,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { FilterButton } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { MediaCategory } from '@/lib/api/schema';

import { GbpMediaCategory } from '@/components/Dialog/MediaCategoryDialog/GbpMediaCategory';
import { useGetMediaCategories } from '@/lib/api/client/media';

type MediaCategoryDialogProps = {
  gbpValues: MediaCategory[];
  onSubmit: (v: { gbpValues: MediaCategory[] }) => void;
};

export function MediaCategoryDialog({
  gbpValues,
  onSubmit,
}: MediaCategoryDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGbpMediaCategories, setSelectedGbpMediaCategories] = useState<
    MediaCategory[]
  >(gbpValues || []);
  useEffect(() => {
    setSelectedGbpMediaCategories(gbpValues);
  }, [gbpValues]);

  function handleOpen() {
    onOpen();
  }

  function handleSubmit() {
    onSubmit({ gbpValues: selectedGbpMediaCategories });
    onClose();
  }

  function handleClose() {
    setSelectedGbpMediaCategories(gbpValues || []);
    onClose();
  }

  const { data: googleMediaCategories, error: googleMediaCategoriesError } =
    useGetMediaCategories({
      swr: {
        enabled: isOpen,
      },
    });

  function handleChangeGbpMediaCategoryCheckbox(values: (string | number)[]) {
    const prevIds = selectedGbpMediaCategories.map((category) => category.id);
    const newIds = values.map(Number);
    const filteredPrevIds = prevIds.filter((id) => newIds.includes(id));
    const combineIds = Array.from(new Set([...filteredPrevIds, ...newIds]));

    const filteredMediaCategories =
      googleMediaCategories?.items
        .map((item) => (combineIds.includes(item.id) ? item : null))
        .filter((v): v is MediaCategory => v !== null) ?? [];

    setSelectedGbpMediaCategories(filteredMediaCategories);
  }

  return (
    <>
      <FilterButton
        label="写真カテゴリ"
        isFilled={gbpValues.length > 0}
        width="auto"
        onClick={handleOpen}
      />
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={handleClose}
        showCloseButton
        title="写真カテゴリで絞り込み"
        buttons={{
          primary: { onClick: handleSubmit },
          secondary: { onClick: handleClose },
        }}
      >
        <Tabs w="full" defaultIndex={0}>
          <TabList>
            <Tab>
              <Flex alignItems="center">Google ビジネスプロフィール</Flex>
            </Tab>
          </TabList>
          <TabIndicator />
          <TabPanels>
            <TabPanel px={1} py={3} overflowY="auto" minH="300px" h="40svh">
              <GbpMediaCategory
                googleMediaCategories={googleMediaCategories}
                error={googleMediaCategoriesError}
                selectedGbpMediaCategories={selectedGbpMediaCategories}
                handleChangeCheckbox={handleChangeGbpMediaCategoryCheckbox}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Modal>
    </>
  );
}

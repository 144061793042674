import { defineStyleConfig } from '@chakra-ui/react';

export const textStyle = defineStyleConfig({
  baseStyle: {
    color: 'black',
    fontWeight: 300,
    resize: 'none',
  },
  variants: {
    invalid: {
      color: 'red.500',
    },
  },
  sizes: {
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
    },
    lg: {
      fontSize: 'lg',
    },
  },
  defaultProps: {
    size: 'md',
  },
});

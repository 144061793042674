'use client';

import { MediaFileResponse, TrimmingInfo } from '@/lib/api/schema';
import {
  Button,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import Cropper, { Area, MediaSize } from 'react-easy-crop';

type Props = {
  imgPath: string;
  crop: {
    x: number;
    y: number;
  };
  zoom: number;
  setCrop: Dispatch<
    SetStateAction<{
      x: number;
      y: number;
    } | null>
  >;
  setZoom: Dispatch<SetStateAction<number | null>>;
  setAreaPixels: Dispatch<SetStateAction<Area | undefined>>;
  cropSize: number;
  setCropSize: Dispatch<SetStateAction<number>>;
  mediaSizeInfo: MediaSize | null;
  setMediaSizeInfo: Dispatch<SetStateAction<MediaSize | null>>;
  onCancel: () => void;
  onDecide: () => void;
  mediaFileDetail: MediaFileResponse;
  isCropped: boolean;
};

export function CropPreviewMediaDialog({
  imgPath,
  crop,
  zoom,
  setCrop,
  setZoom,
  setAreaPixels,
  cropSize,
  setCropSize,
  mediaSizeInfo,
  setMediaSizeInfo,
  onCancel,
  onDecide,
  mediaFileDetail,
  isCropped,
}: Props) {
  const [imgRef, setImageRef] = useState<RefObject<HTMLImageElement> | null>(
    null,
  );
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (imgRef?.current && mediaSizeInfo) {
      imgRef.current.width = Math.floor(mediaSizeInfo.width);
      imgRef.current.height = Math.floor(mediaSizeInfo.height);
    }
  }, [imgRef, mediaSizeInfo]);

  return (
    <>
      <Modal isOpen onClose={onCancel} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>写真をトリミング</ModalHeader>
          <ModalBody>
            <Center h="396px" position="relative" mb={-2}>
              <Cropper
                setImageRef={(ref) => {
                  if (ref.current) {
                    setImageRef(ref);
                  }
                }}
                image={imgPath}
                cropSize={{ width: cropSize, height: cropSize }}
                crop={crop ?? { x: 0, y: 0 }}
                zoom={zoom ?? 1}
                aspect={4 / 4}
                style={{
                  containerStyle: {
                    visibility: isInit ? 'visible' : 'hidden',
                  },
                }}
                onMediaLoaded={(mediaSize) => {
                  setMediaSizeInfo(mediaSize);

                  const size = Math.floor(
                    Math.min(mediaSize.width, mediaSize.height),
                  );
                  setCropSize(size);

                  if (!isInit) {
                    setTimeout(() => {
                      setIsInit(true);
                    }, 250);
                  }

                  if (!isCropped) {
                    setTimeout(() => {
                      const hasCropInfo = hasAllTrimInfo(
                        mediaFileDetail.mediaFile.trimmingInfo,
                      );

                      if (hasCropInfo) {
                        setCrop({
                          x: Number(
                            mediaFileDetail.mediaFile.trimmingInfo?.crop.x,
                          ),
                          y: Number(
                            mediaFileDetail.mediaFile.trimmingInfo?.crop.y,
                          ),
                        });
                        setAreaPixels({
                          width: Number(
                            mediaFileDetail.mediaFile.trimmingInfo?.areaPixels
                              .width,
                          ),
                          height: Number(
                            mediaFileDetail.mediaFile.trimmingInfo?.areaPixels
                              .height,
                          ),
                          x: Number(
                            mediaFileDetail.mediaFile.trimmingInfo?.areaPixels
                              .x,
                          ),
                          y: Number(
                            mediaFileDetail.mediaFile.trimmingInfo?.areaPixels
                              .y,
                          ),
                        });
                        setZoom(
                          Number(mediaFileDetail.mediaFile.trimmingInfo?.zoom),
                        );
                      }
                    }, 250);
                  }
                }}
                onCropComplete={(_, area) => setAreaPixels(area)}
                onCropChange={setCrop}
                onZoomChange={setZoom}
              />
            </Center>
          </ModalBody>
          <ModalFooter as={Flex} gap={3} justify="end">
            <Button variant="secondary" onClick={onCancel}>
              キャンセル
            </Button>
            <Button onClick={onDecide}>決定</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function isValidNumber(value: string) {
  if (value === undefined || value === '') {
    return false;
  }
  return !Number.isNaN(value);
}

function hasAllTrimInfo(trimmingInfo?: TrimmingInfo) {
  if (!trimmingInfo) return false;
  // チェックする値のリスト
  const valuesToCheck = [
    trimmingInfo?.areaPixels.x,
    trimmingInfo?.areaPixels.y,
    trimmingInfo.areaPixels.width,
    trimmingInfo.areaPixels.height,
    trimmingInfo.crop.x,
    trimmingInfo.crop.y,
    trimmingInfo.zoom,
  ];

  const allValuesValid = valuesToCheck.every(isValidNumber);

  if (allValuesValid) {
    return true;
  } else {
    return false;
  }
}

import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { MultiMessage } from '@/components/Message';
import { useState } from 'react';
import { PostGroupRejectsItem } from '@/lib/api/schema';
import { format, parseISO } from 'date-fns';

type PostRejectedMessageProps = {
  reasons: PostGroupRejectsItem[];
};

export function PostRejectedMessage({ reasons }: PostRejectedMessageProps) {
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);

  return (
    <MultiMessage
      messages={[
        {
          text: `${reasons[0].rejectedByName}さんが承認申請を却下しました。内容を修正して再申請してください。\nなお、対象店舗や投稿タイプを変更するには、この申請を削除して投稿を作り直してください。`,
          fontSize: '12px',
          icon: {
            name: 'attention',
            color: 'red.500',
            size: 'sm',
          },
        },
      ]}
      bgColor="red.100"
    >
      {reasons.map(
        (reason, i) =>
          (isDetailOpen || i === 0) && (
            <Stack key={`${reason.rejectedByName}_${i}`}>
              <Flex alignItems="center">
                <Text color="red.500" fontWeight="bold" fontSize="sm">
                  却下理由
                </Text>
                <Text color="gray.400" fontSize="xs">
                  （{reason.rejectedByName},{' '}
                  {format(parseISO(reason.rejectedAt), 'yyyy.MM.dd')}）
                </Text>
              </Flex>
              <Text fontSize="sm" whiteSpace="pre-wrap">
                {reason.reason}
              </Text>
            </Stack>
          ),
      )}
      {reasons.length > 1 && (
        <Box
          color="blue.500"
          fontSize="sm"
          cursor="pointer"
          mr="auto"
          onClick={() => setIsDetailOpen(!isDetailOpen)}
        >
          {isDetailOpen ? '履歴をたたむ' : '履歴を見る'}
        </Box>
      )}
    </MultiMessage>
  );
}

'use client';

import { Stack, Text } from '@chakra-ui/react';
import { Modal } from '@/components/Modal';
import { MultiMessage } from '@/components/Message';

type MenuUpdateAlertModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
  onSubmit: () => void;
};

export function MenuUpdateAlertModal({
  isOpen,
  onCloseModal,
  onSubmit,
}: MenuUpdateAlertModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      title="メニュー更新時の注意事項"
      buttons={{
        secondary: { label: 'キャンセル', onClick: onCloseModal },
        primary: { label: '更新', onClick: onSubmit },
      }}
      modalBody={{
        sx: {
          whiteSpace: 'pre-line',
        },
      }}
    >
      <Stack gap={3}>
        <MultiMessage
          messages={[
            {
              text: `更新対象のメニューに設定されている写真は削除されます。`,
              fontSize: '14px',
              icon: {
                name: 'attention',
                color: 'red.500',
                size: 'sm',
              },
            },
          ]}
          bgColor="red.100"
        />
        <Text>
          CSVファイルでは画像データを扱えないため、アップロード時に「画像なし」として既存のメニューの画像は削除されます。
        </Text>
        <Text>
          画像の追加・更新は、各店舗の編集画面の[Google
          ビジネスプロフィール]タブから行ってください。
        </Text>
      </Stack>
    </Modal>
  );
}

import { Button, type ButtonProps } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';

type ToggleButtonProps = {
  label: string;
  isEnabled: boolean | null;
} & ButtonProps;

export function ToggleButton({
  label,
  isEnabled,
  onClick,
  ...rest
}: ToggleButtonProps) {
  return (
    <Button
      variant={
        isEnabled === null ? 'secondary' : isEnabled ? 'primary' : 'negative'
      }
      h="30px"
      px={4}
      fontWeight={300}
      lineHeight="20px"
      borderRadius="15px"
      leftIcon={
        isEnabled === null ? (
          <Icon name="add" color="blue.500" size="xs" />
        ) : isEnabled ? (
          <Icon name="check" color="white" size="xs" />
        ) : (
          <Icon name="remove" color="gray.500" size="xs" />
        )
      }
      onClick={onClick}
      overflowWrap="normal"
      whiteSpace="unset"
      {...rest}
    >
      {label}
    </Button>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type FunctionNameParameter = typeof FunctionNameParameter[keyof typeof FunctionNameParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FunctionNameParameter = {
  bulkInsertStore: 'bulkInsertStore',
  bulkUpdateMultiPlatform: 'bulkUpdateMultiPlatform',
  storeImages: 'storeImages',
  gbp: 'gbp',
  postGbp: 'postGbp',
  createUsers: 'createUsers',
  bundleUpdateMenu: 'bundleUpdateMenu',
  bundleUpdateService: 'bundleUpdateService',
} as const;

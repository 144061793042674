import { defineStyleConfig } from '@chakra-ui/react';

export const buttonStyle = defineStyleConfig({
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
  baseStyle: {
    lineHeight: 'normal',
  },
  variants: {
    primary: {
      bgColor: 'blue.500',
      color: 'white',
      _disabled: {
        opacity: 0.3,
      },
      _hover: {
        _before: {
          position: 'absolute',
          content: '""',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          bgColor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '4px',
        },
        _disabled: {
          bgColor: 'blue.500',
          color: 'white',
          opacity: 0.3,
        },
      },
    },
    danger: {
      bgColor: 'red.500',
      color: 'white',
      _disabled: {
        bgColor: 'red.500',
        color: 'white',
        opacity: 0.3,
      },
      _hover: {
        _before: {
          position: 'absolute',
          content: '""',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          bgColor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '4px',
        },
        _disabled: {
          bgColor: 'red.500',
          color: 'white',
          opacity: 0.3,
        },
      },
    },
    secondary: {
      bgColor: 'white',
      color: 'blue.500',
      borderColor: 'blue.500',
      borderWidth: '1px',
      _disabled: {
        opacity: 0.3,
        svg: {
          fill: 'gray.300',
        },
      },
      _hover: {
        _before: {
          position: 'absolute',
          content: '""',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          bgColor: 'rgba(49, 139, 247, 0.05)',
          borderRadius: '4px',
        },
        svg: {
          fill: 'blue.400',
        },
        _disabled: {
          bgColor: 'white',
          color: 'blue.500',
          borderColor: 'blue.500',
          svg: {
            fill: 'gray.300',
          },
        },
      },
    },
    tertiary: {
      bgColor: 'blue.100',
      color: 'blue.500',
      _disabled: {
        opacity: 0.3,
      },
      _hover: {
        _before: {
          position: 'absolute',
          content: '""',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          bgColor: 'rgba(49, 139, 247, 0.05)',
          borderRadius: '4px',
        },
        _disabled: {
          bgColor: 'blue.100',
          color: 'blue.500',
        },
      },
    },
    text: {
      bgColor: 'transparent',
      color: 'blue.500',
      _disabled: {
        bgColor: 'gray.100',
        color: 'gray.300',
      },
    },
    negative: {
      bgColor: 'gray.200',
      color: 'gray.500',
      _disabled: {
        opacity: 0.3,
      },
      _hover: {
        _before: {
          position: 'absolute',
          content: '""',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          bgColor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '4px',
        },
        color: 'gray.500',
        _disabled: {
          bgColor: 'gray.200',
          color: 'gray.500',
        },
      },
    },
    rounded: {
      bgColor: 'blue.500',
      color: 'white',
      borderColor: 'gray.200',
      borderWidth: '1px',
      borderRadius: '15px',
      _hover: {
        bgColor: 'blue.400',
        color: 'white',
        svg: {
          fill: 'white',
        },
      },
    },
    roundedOutline: {
      bgColor: 'white',
      color: 'black',
      borderColor: 'gray.200',
      borderWidth: '1px',
      borderRadius: '15px',
      _hover: {
        bgColor: 'blue.50',
      },
    },
  },
  sizes: {
    sm: {
      h: '28px',
      p: '5px 12px',
      borderRadius: '4px',
      fontSize: 'md',
      fontWeight: '300',
      lineHeight: '17px',
    },
    md: {
      h: '36px',
      p: '8px 16px',
      borderRadius: '4px',
      fontSize: 'md',
      fontWeight: '600',
      lineHeight: '20px',
    },
  },
});

'use client';

import { Icon } from '@/components/Icon';
import { Flex, Stack, StackProps, Text } from '@chakra-ui/react';

type DataFetchErrorMessageProps = {
  responseStatus: number;
} & StackProps;

export function DataFetchErrorMessage({
  responseStatus,
  ...rest
}: DataFetchErrorMessageProps) {
  return (
    <Flex
      h="full"
      w="full"
      flexDirection="column"
      align="center"
      justify="center"
      pb="2%"
      gap={3.5}
      {...rest}
    >
      <Icon name="noResults" color="gray.200" size="xl" />
      <Stack gap={3}>
        <Text size="md" color="gray.500" align="center">
          {responseStatus >= 500
            ? '一時的なエラーが発生しました。'
            : '検索中にエラーが発生しました。'}
        </Text>
        <Text size="sm" color="gray.400" align="center">
          {responseStatus >= 500
            ? '数分ほど時間をおいてもう一度お試しください。'
            : '担当者にお問い合わせください。'}
        </Text>
      </Stack>
    </Flex>
  );
}

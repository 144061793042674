import { Icon } from '@/components/Icon';
import { Box, Tooltip, Img, IconButton, Center } from '@chakra-ui/react';

export function EditingAssetItem(props: {
  type: string;
  dataURL: string;
  tip?: string;
  onRemove: () => void;
}) {
  const { type, dataURL, tip, onRemove } = props;

  return (
    <Tooltip
      placement="top"
      hasArrow
      bgColor="red.500"
      color="white"
      maxW="180px"
      py={1}
      px={2}
      fontSize="sm"
      label={tip}
    >
      <Box pos="relative" pt={2} pr={2}>
        <Img
          src={dataURL}
          w="72px"
          h="72px"
          maxH="72px"
          maxW="72px"
          borderRadius={4}
          objectFit="cover"
          _hover={{ opacity: 0.5 }}
        />
        {type.startsWith('video/') && (
          <Center
            pos="absolute"
            top={2}
            left={0}
            w="72px"
            h="72px"
            borderRadius={4}
            zIndex={5}
          >
            <Icon name="video" size="lg" color="white" />
          </Center>
        )}
        {tip && (
          <Center
            pos="absolute"
            top={2}
            left={0}
            w="72px"
            h="72px"
            bg="rgba(235,77,56,0.5)"
            borderRadius={4}
            zIndex={10}
          >
            <Icon name="attention" size="lg" color="white" />
          </Center>
        )}
        <IconButton
          variant="text"
          size="xs"
          w="16px"
          h="24px"
          pos="absolute"
          top="-2px"
          right="-6px"
          bg="white"
          borderRadius="full"
          aria-label="remove"
          onClick={onRemove}
          zIndex={20}
        >
          <Icon name="closeCircle" />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 媒体連携ステータス
- NOT_LINKED: 未連携
- PROCESSING: 連携処理中
- LINKED: 連携

 */
export type PlatformLinkStatus = typeof PlatformLinkStatus[keyof typeof PlatformLinkStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformLinkStatus = {
  NOT_LINKED: 'NOT_LINKED',
  PROCESSING: 'PROCESSING',
  LINKED: 'LINKED',
} as const;

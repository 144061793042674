'use client';

import { helpText } from '@/assets/i18n/ja';
import { DatePicker } from '@/components/DatePicker';
import { ConfirmLink } from '@/components/Link';
import { SelectTime } from '@/components/Select';
import { HelpText } from '@/types/store';
import { Box, Button, Flex, Radio, RadioGroup } from '@chakra-ui/react';
import { format, formatDate, isAfter, parse, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';

const help = helpText.post;

type ScheduleSubmitFormProps = {
  redirectHref: string;
  defaultDate?: string | null;
  cancelButtonText?: string;
  submitButtonText?: string;
  isShowSubmitButton?: boolean;
  isNewPost: boolean;
  isDirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  onSetHelpText: (val: HelpText | undefined) => void;
  onChangeSchedule: (val: string | null) => void;
  onSubmit: () => void;
};

export function ScheduleSubmitForm({
  redirectHref,
  defaultDate,
  cancelButtonText,
  submitButtonText,
  isShowSubmitButton = true,
  isNewPost,
  isDirty,
  isValid,
  isSubmitting,
  onSetHelpText,
  onChangeSchedule,
  onSubmit,
}: ScheduleSubmitFormProps) {
  const [isScheduled, setIsScheduled] = useState<boolean>(Boolean(defaultDate));
  const [scheduleDate, setScheduleDate] = useState<string>(
    defaultDate ? format(parseISO(defaultDate), 'yyyy-MM-dd') : '',
  );
  const [scheduleTime, setScheduleTime] = useState<string>(
    defaultDate ? format(parseISO(defaultDate), 'HH:mm') : '',
  );
  const isScheduleValid =
    !isScheduled || (scheduleDate !== '' && scheduleTime !== '');

  const handleChangeRadioGroup = useCallback(
    (val: string) => {
      const hasSchedule = val === 'schedule';
      if (hasSchedule) {
        if (scheduleDate && scheduleTime) {
          const d = new Date(`${scheduleDate} ${scheduleTime}`);
          onChangeSchedule(d.toISOString());
        }
      } else {
        onChangeSchedule(null);
      }
      setIsScheduled(hasSchedule);
    },
    [onChangeSchedule, scheduleDate, scheduleTime],
  );

  const handleChangeDatePicker = useCallback(
    (v: Date | null) => {
      if (v) {
        const date = formatDate(v, 'yyyy-MM-dd');
        setScheduleDate(date);
        if (!scheduleTime) return;
        if (
          isAfter(
            parse(date + ' ' + scheduleTime, 'yyyy-MM-dd HH:mm', new Date()),
            new Date(),
          )
        ) {
          const d = new Date(`${date} ${scheduleTime}`);
          onChangeSchedule(d.toISOString());
        } else {
          setScheduleTime('');
          onChangeSchedule(null);
        }
      }
    },
    [onChangeSchedule, scheduleTime],
  );

  const handleChangeSelectTime = useCallback(
    (v: string | null) => {
      if (v) {
        setScheduleTime(v);
        if (!scheduleDate) return;
        if (
          isAfter(
            parse(scheduleDate + ' ' + v, 'yyyy-MM-dd HH:mm', new Date()),
            new Date(),
          )
        ) {
          const d = new Date(`${scheduleDate} ${v}`);
          onChangeSchedule(d.toISOString());
        } else {
          setScheduleTime('');
          onChangeSchedule(null);
        }
      }
    },
    [onChangeSchedule, scheduleDate],
  );

  return (
    <>
      <RadioGroup
        display="flex"
        gap={3}
        value={isScheduled ? 'schedule' : 'now'}
        onChange={handleChangeRadioGroup}
      >
        <Flex
          onMouseEnter={() => onSetHelpText(help.now)}
          onMouseLeave={() => onSetHelpText(undefined)}
        >
          <Radio value={'now'}>現在時刻で{isNewPost ? '投稿' : '更新'}</Radio>
        </Flex>
        <Flex
          onMouseEnter={() => onSetHelpText(help.scheduleTime)}
          onMouseLeave={() => onSetHelpText(undefined)}
        >
          <Radio value={'schedule'}>{isNewPost ? '投稿' : '更新'}の予約</Radio>
        </Flex>
      </RadioGroup>
      <Flex
        gap={3}
        align="center"
        mx={3}
        onMouseEnter={() => onSetHelpText(help.scheduleTime)}
        onMouseLeave={() => onSetHelpText(undefined)}
      >
        <Box maxW="160px">
          <DatePicker
            disabled={!isScheduled}
            minDate={new Date()}
            value={scheduleDate.replace(/-/g, '.')}
            onChange={handleChangeDatePicker}
          />
        </Box>
        <SelectTime
          placeholder="--:--"
          isDisabled={!isScheduled}
          value={scheduleTime}
          onChange={handleChangeSelectTime}
        />
      </Flex>
      <Flex gap={3} align="center">
        <ConfirmLink
          href={redirectHref}
          isDirty={isDirty}
          isValid={isValid && isScheduleValid}
          onSave={onSubmit}
        >
          <Button variant="secondary">
            {cancelButtonText || 'キャンセル'}
          </Button>
        </ConfirmLink>
        {isShowSubmitButton && (
          <Button
            isDisabled={!(isValid && isScheduleValid)}
            isLoading={isSubmitting}
            onClick={onSubmit}
          >
            {submitButtonText || '更新'}
          </Button>
        )}
      </Flex>
    </>
  );
}

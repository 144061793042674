import { Modal } from '@/components/Modal';
import { Text } from '@chakra-ui/react';

type EditRankingKeywordConfirmationDialogProps = {
  onOk: () => void;
  onCancel: () => void;
  isDisabled?: boolean;
};

export function EditRankingKeywordConfirmationDialog({
  onOk,
  onCancel,
  isDisabled,
}: EditRankingKeywordConfirmationDialogProps) {
  return (
    <Modal
      isOpen={true}
      isDisabled={isDisabled}
      onClose={onCancel}
      title="検索キーワード変更時の注意事項"
      buttons={{
        primary: { label: '変更', onClick: onOk },
        secondary: { onClick: onCancel },
      }}
    >
      <Text>
        検索キーワードを変更すると、新しい検索キーワードでの計測結果は24時間以内に適用されます。
        <br />
        なお、変更前のデータは参照できなくなります。
      </Text>
    </Modal>
  );
}

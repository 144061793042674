import { Box, type BoxProps, Center, Spinner } from '@chakra-ui/react';

export function LoadingPanel({ ...rest }: BoxProps) {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      w="full"
      h="full"
      bg="overlayWhite"
      zIndex={5}
      {...rest}
    >
      <Center w="full" h="full">
        <Spinner
          size="xl"
          color="blue.500"
          emptyColor="gray.200"
          thickness="3px"
        />
      </Center>
    </Box>
  );
}

'use client';

import { Loader } from '@/components/Loader';
import { Toast } from '@/components/Toast';
import { env } from '@/constants/env';
import { apiErrorRoutingHandler } from '@/lib/api/mutator/custom-instance';
import { GeneralError } from '@/lib/api/schema';
import { loaderAtom } from '@/lib/atoms/loader';
import { theme } from '@/styles';
import { CacheProvider } from '@chakra-ui/next-js';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';

export function Providers({ children }: { children: React.ReactNode }) {
  const toast = useToast();
  const router = useRouter();
  const [isLoading] = useAtom(loaderAtom);

  useEffect(() => {
    if (env.channelTalk.ENABLE_CHANNEL_TALK) {
      ChannelService.loadScript();

      ChannelService.boot({
        pluginKey: env.channelTalk.PLUGIN_KEY,
      });
    }
  }, []);

  return (
    <CacheProvider>
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            position: 'top',
            duration: 7000,
            isClosable: true,
            render: (props) => <Toast {...props} />,
          },
        }}
      >
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            onError: (error: GeneralError) => {
              apiErrorRoutingHandler(router, error);
              toast({
                position: 'top',
                duration: null,
                isClosable: true,
                render: (props) => <Toast {...props} errorResponse={error} />,
              });
            },
          }}
        >
          {children}
          <Loader isOpen={isLoading} />
        </SWRConfig>
      </ChakraProvider>
    </CacheProvider>
  );
}

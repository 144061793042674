/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * Facebook営業時間タイプ,
0:特定の営業時間に営業,
1:24時間営業,
2:営業時間記載なし,
3:閉店・休業
4:一時休業中
 */
export type FacebookPageDetailImmutableBusinessHoursType = typeof FacebookPageDetailImmutableBusinessHoursType[keyof typeof FacebookPageDetailImmutableBusinessHoursType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FacebookPageDetailImmutableBusinessHoursType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import useSWRMutation from 'swr/mutation'
import type {
  SWRMutationConfiguration
} from 'swr/mutation'
import type {
  ApplyMediaFilesBody,
  DeleteMediaFilesBody,
  ErrorForbiddenResponse,
  ErrorInternalServerResponse,
  ErrorNotFoundResponse,
  ErrorUnauthenticatedResponse,
  ErrorValidationResponse,
  GetMediaFilesParams,
  MediaCategoriesResponse,
  MediaFileResponse,
  MediaFilesResponse,
  RegisterMediaFilesBody,
  RegisterMediaFilesResponse,
  Success,
  UpdateMediaFilesBody
} from '../schema'
import { customInstance } from '../mutator/custom-instance';



  
  /**
 * 各プラットフォーム（GBP、Yahoo、Apple）のメディアファイル一覧を取得する
 * @summary メディアファイル一覧を取得する
 */
export const getMediaFiles = (
    params?: GetMediaFilesParams,
 ) => {
      return customInstance<MediaFilesResponse>(
      {url: `/v2/media-files`, method: 'GET',
        params
    },
      );
    }
  


export const getGetMediaFilesKey = (params?: GetMediaFilesParams,) => [`/v2/media-files`, ...(params ? [params]: [])] as const;


export type GetMediaFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaFiles>>>
export type GetMediaFilesQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary メディアファイル一覧を取得する
 */
export const useGetMediaFiles = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
  params?: GetMediaFilesParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getMediaFiles>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetMediaFilesKey(params) : null);
  const swrFn = () => getMediaFiles(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * S3にアップロード済みのメディアファイルを登録します
 * @summary メディアファイル登録
 */
export const registerMediaFiles = (
    registerMediaFilesBody: RegisterMediaFilesBody,
 ) => {
      return customInstance<RegisterMediaFilesResponse>(
      {url: `/v2/media-files`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registerMediaFilesBody
    },
      );
    }
  


export const getRegisterMediaFilesMutationFetcher = ( ) => {
  return (_: string, { arg }: { arg: RegisterMediaFilesBody }): Promise<RegisterMediaFilesResponse> => {
    return registerMediaFiles(arg);
  }
}
export const getRegisterMediaFilesMutationKey = () => `/v2/media-files` as const;

export type RegisterMediaFilesMutationResult = NonNullable<Awaited<ReturnType<typeof registerMediaFiles>>>
export type RegisterMediaFilesMutationError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary メディアファイル登録
 */
export const useRegisterMediaFiles = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof registerMediaFiles>>, TError, string, RegisterMediaFilesBody, Awaited<ReturnType<typeof registerMediaFiles>>> & { swrKey?: string },  }
) => {

  const {swr: swrOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getRegisterMediaFilesMutationKey();
  const swrFn = getRegisterMediaFilesMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * メディアファイルをカンリー・外部媒体から一括削除します。処理は一括削除queue-workerで行います。
 * @summary メディアファイルを一括削除する
 */
export const deleteMediaFiles = (
    deleteMediaFilesBody: DeleteMediaFilesBody,
 ) => {
      return customInstance<Success>(
      {url: `/v2/media-files`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteMediaFilesBody
    },
      );
    }
  


export const getDeleteMediaFilesMutationFetcher = ( ) => {
  return (_: string, { arg }: { arg: DeleteMediaFilesBody }): Promise<Success> => {
    return deleteMediaFiles(arg);
  }
}
export const getDeleteMediaFilesMutationKey = () => `/v2/media-files` as const;

export type DeleteMediaFilesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMediaFiles>>>
export type DeleteMediaFilesMutationError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary メディアファイルを一括削除する
 */
export const useDeleteMediaFiles = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteMediaFiles>>, TError, string, DeleteMediaFilesBody, Awaited<ReturnType<typeof deleteMediaFiles>>> & { swrKey?: string },  }
) => {

  const {swr: swrOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteMediaFilesMutationKey();
  const swrFn = getDeleteMediaFilesMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 複数メディアファイルと各プラットフォーム（GBP、Yahoo、Apple）の連携を更新する。処理はqueue-workerで行う。
 * @summary メディアファイルを外部媒体へ一括適用する
 */
export const applyMediaFiles = (
    applyMediaFilesBody: ApplyMediaFilesBody,
 ) => {
      return customInstance<Success>(
      {url: `/v2/media-files/apply`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: applyMediaFilesBody
    },
      );
    }
  


export const getApplyMediaFilesMutationFetcher = ( ) => {
  return (_: string, { arg }: { arg: ApplyMediaFilesBody }): Promise<Success> => {
    return applyMediaFiles(arg);
  }
}
export const getApplyMediaFilesMutationKey = () => `/v2/media-files/apply` as const;

export type ApplyMediaFilesMutationResult = NonNullable<Awaited<ReturnType<typeof applyMediaFiles>>>
export type ApplyMediaFilesMutationError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary メディアファイルを外部媒体へ一括適用する
 */
export const useApplyMediaFiles = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof applyMediaFiles>>, TError, string, ApplyMediaFilesBody, Awaited<ReturnType<typeof applyMediaFiles>>> & { swrKey?: string },  }
) => {

  const {swr: swrOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getApplyMediaFilesMutationKey();
  const swrFn = getApplyMediaFilesMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 指定されたメディアファイルIDに関連する全プラットフォーム（Google、Yahoo、Apple）の情報を取得する
 * @summary メディアファイル詳細を取得する
 */
export const getMediaFileDetail = (
    mediaFileId: string,
 ) => {
      return customInstance<MediaFileResponse>(
      {url: `/v2/media-files/${mediaFileId}`, method: 'GET'
    },
      );
    }
  


export const getGetMediaFileDetailKey = (mediaFileId: string,) => [`/v2/media-files/${mediaFileId}`] as const;


export type GetMediaFileDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaFileDetail>>>
export type GetMediaFileDetailQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary メディアファイル詳細を取得する
 */
export const useGetMediaFileDetail = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  mediaFileId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getMediaFileDetail>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(mediaFileId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetMediaFileDetailKey(mediaFileId) : null);
  const swrFn = () => getMediaFileDetail(mediaFileId, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * メディアファイルと各プラットフォーム（GBP、Yahoo、Apple）の連携を更新する。処理はqueue-workerで行う。
 * @summary メディアファイルを更新する
 */
export const updateMediaFiles = (
    mediaFileId: string,
    updateMediaFilesBody: UpdateMediaFilesBody,
 ) => {
      return customInstance<Success>(
      {url: `/v2/media-files/${mediaFileId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateMediaFilesBody
    },
      );
    }
  


export const getUpdateMediaFilesMutationFetcher = (mediaFileId: string, ) => {
  return (_: string, { arg }: { arg: UpdateMediaFilesBody }): Promise<Success> => {
    return updateMediaFiles(mediaFileId, arg);
  }
}
export const getUpdateMediaFilesMutationKey = (mediaFileId: string,) => `/v2/media-files/${mediaFileId}` as const;

export type UpdateMediaFilesMutationResult = NonNullable<Awaited<ReturnType<typeof updateMediaFiles>>>
export type UpdateMediaFilesMutationError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary メディアファイルを更新する
 */
export const useUpdateMediaFiles = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  mediaFileId: string, options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof updateMediaFiles>>, TError, string, UpdateMediaFilesBody, Awaited<ReturnType<typeof updateMediaFiles>>> & { swrKey?: string },  }
) => {

  const {swr: swrOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateMediaFilesMutationKey(mediaFileId);
  const swrFn = getUpdateMediaFilesMutationFetcher(mediaFileId,);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * メディアカテゴリー一覧を取得する
 * @summary メディアカテゴリー一覧を取得する
 */
export const getMediaCategories = (
    
 ) => {
      return customInstance<MediaCategoriesResponse>(
      {url: `/v2/media-files/gbp-categories`, method: 'GET'
    },
      );
    }
  


export const getGetMediaCategoriesKey = () => [`/v2/media-files/gbp-categories`] as const;


export type GetMediaCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaCategories>>>
export type GetMediaCategoriesQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary メディアカテゴリー一覧を取得する
 */
export const useGetMediaCategories = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
   options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getMediaCategories>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetMediaCategoriesKey() : null);
  const swrFn = () => getMediaCategories();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

import { Button, type ButtonProps } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';

type FilterButtonProps = {
  label: string;
  isFilled: boolean;
  width: string;
} & ButtonProps;

export function FilterButton({
  label,
  isFilled,
  width,
  onClick,
  ...rest
}: FilterButtonProps) {
  return (
    <>
      {isFilled ? (
        <Button
          w={width}
          minW={width}
          variant="rounded"
          h="30px"
          p="5px 12px"
          fontSize="md"
          fontWeight="normal"
          lineHeight="20px"
          padding="0 16px"
          rightIcon={<Icon name="chevronDown" color="white" size="sm" />}
          onClick={onClick}
          {...rest}
        >
          {label}
        </Button>
      ) : (
        <Button
          w={width}
          minW={width}
          variant="roundedOutline"
          h="30px"
          p="5px 12px"
          fontSize="md"
          fontWeight="normal"
          lineHeight="20px"
          padding="0 16px"
          rightIcon={<Icon name="chevronDown" color="blue.500" size="sm" />}
          onClick={onClick}
          {...rest}
        >
          {label}
        </Button>
      )}
    </>
  );
}

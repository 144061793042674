import { Icon } from '@/components/Icon';
import { Flex, Text, Tooltip, type FlexProps } from '@chakra-ui/react';

type AddAssetItemButtonProps = {
  buttonText?: string;
  isDisabled?: boolean;
  tooltipText?: string;
} & FlexProps;

export function AddAssetItemButton({
  buttonText,
  isDisabled,
  tooltipText,
  ...rest
}: AddAssetItemButtonProps) {
  return (
    <Tooltip
      label={tooltipText}
      placement="top"
      isDisabled={!isDisabled}
      hasArrow
    >
      <Flex
        w={144}
        h={144}
        direction="column"
        align="center"
        justify="center"
        gap={2}
        bg={isDisabled ? 'gray.100' : 'blue.50'}
        borderRadius={4}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        _hover={{ bg: isDisabled ? 'gray.100' : 'blue.100' }}
        {...rest}
      >
        <Icon
          name="addPhoto"
          size="lg"
          color={isDisabled ? 'gray.300' : 'blue.500'}
        />
        <Text color={isDisabled ? 'gray.300' : 'blue.500'}>
          {buttonText || 'さらに追加'}
        </Text>
      </Flex>
    </Tooltip>
  );
}

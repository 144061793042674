'use client';

import { EditingAssetItem } from './EditingAssetItem';
import { AddAssetItemButton } from '@/components/Button';
import { Message } from '@/components/Message';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  SimpleGrid,
  Text,
  useDisclosure,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { useFileUpload } from '@/hooks/useFileUpload';
import { createVideoThumbnail, removeExifData } from '@/utils/file';

export type NewItemData = {
  category: string;
  fileItems: FileItem[];
};

export type FileItem = {
  name: string;
  size: number;
  type: string;
  displayUrl: string;
  rowFile: File;
  originalUrl?: string;
  error?: string;
};

type EditAssetItemsDialogProps = {
  storeId: string;
  category: string;
  onSubmit: (value: NewItemData) => void;
};

export function EditAssetItemsDialog({
  storeId,
  category,
  onSubmit,
}: EditAssetItemsDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const [items, setItems] = useState<FileItem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);
  const { gbpFileUpload } = useFileUpload();

  useEffect(() => {
    if (isOpen) {
      setItems([]);
      setErrorMessage('');
    }
  }, [isOpen]);

  async function handleFiles(fileList: FileList) {
    setErrorMessage('');
    if (!fileList) return;
    const files = Array.from(fileList);
    if (files.length + items.length > 20) {
      setErrorMessage('一度にアップロードできる枚数は20枚までです。');
      return;
    }
    const totalSize =
      files.reduce((pv, c) => pv + c.size, 0 as number) +
      items.reduce((pv, c) => pv + c.size, 0 as number);
    if (totalSize > 1024 * 1024 * 1024 * 75) {
      setErrorMessage('一度にアップロードできるサイズは75GBまでです。');
      return;
    }

    const dataList: FileItem[] = [];
    for (const file of files) {
      if (
        !(
          file.type.startsWith('video/') ||
          file.type === 'image/jpeg' ||
          file.type === 'image/png'
        )
      ) {
        setErrorMessage(
          'アップロードできないファイル形式があります。以下のファイル形式でアップロードしてください。\n・写真：JPG、PNG',
        );
        return;
      }
      const { name, type, size } = file;
      let errorMessage = null;
      if (file.type.startsWith('video/')) {
        if (size > 1024 * 1024 * 25) {
          errorMessage = `ファイルサイズが大きすぎます（${Math.floor(size / 1024 / 1024)}MB）。25MB以下のファイルをアップロードしてください。`;
        }
        const url = await createVideoThumbnail(file);
        dataList.push({ name, type, size, displayUrl: url, rowFile: file });
      } else {
        if (size > 1024 * 1024 * 5) {
          errorMessage = `ファイルサイズが大きすぎます（${Math.floor(size / 1024 / 1024)}MB）。5MB以下のファイルをアップロードしてください。`;
        }
        const fr = new FileReader();
        const data = await removeExifData(file);
        fr.readAsDataURL(data);
        await new Promise<void>((resolve) => {
          fr.onload = async (e) => {
            const dataUrl = e.target?.result as string;
            await new Promise<void>((resolve) => {
              const elm = document.createElement('img');
              elm.src = dataUrl;
              elm.onload = () => {
                if (elm.width < 250 || elm.height < 250) {
                  errorMessage =
                    'ファイルの縦横サイズが小さすぎます。縦250ピクセル・横250ピクセル以上のファイルをアップロードしてください。';
                }
                resolve();
              };
            });
            resolve();
          };
        });
        dataList.push({
          name,
          type,
          size,
          displayUrl: fr.result as string,
          rowFile: data,
          error: errorMessage ?? undefined,
        });
      }
    }
    setItems((prev) => [...prev, ...dataList]);
  }

  function removeFile(index: number) {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setItems((prev) => prev.toSpliced(index, 1));
  }

  function handleCancel() {
    onClose();
  }

  async function handleOk() {
    setIsUploading(true);
    const newItems = [...items];
    for (const item of newItems) {
      try {
        const originalUrl = await gbpFileUpload(storeId, item.rowFile);
        if (originalUrl) {
          item.originalUrl = originalUrl;
        }
      } catch (error) {
        // No Operation
      }
    }
    const result = newItems.reduce(
      (acc, obj) => {
        if (obj.originalUrl) {
          acc.success.push(obj);
        } else {
          acc.error.push(obj);
        }
        return acc;
      },
      { success: [] as FileItem[], error: [] as FileItem[] },
    );
    if (result.error.length > 0) {
      onSubmit({
        category,
        fileItems: result.success,
      });
      setErrorMessage(
        'データの保存中にエラーが発生しました。もう一度決定ボタンを押してください。',
      );
      setIsUploading(false);
      setItems(result.error);
    } else {
      onSubmit({
        category,
        fileItems: newItems,
      });
      setIsUploading(false);
      onClose();
      setItems([]);
    }
  }

  return (
    <>
      <AddAssetItemButton onClick={onOpen} />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        variant={'fixedHeight'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>写真・動画を追加</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={2} height={'340px'}>
              {errorMessage && (
                <Message
                  type="error"
                  color="gray.500"
                  width="100%"
                  h="auto"
                  py={2}
                  alignItems="start"
                >
                  <Text whiteSpace="pre-wrap">{errorMessage}</Text>
                </Message>
              )}
              <VStack
                bg="blue.50"
                p={8}
                flex={1}
                justifyContent="center"
                alignItems="center"
                borderRadius={4}
                maxH="340px"
                width="100%"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  handleFiles(e.dataTransfer.files);
                }}
              >
                <Text textAlign="center">
                  ここに写真・動画をドラッグ
                  <br />
                  または
                </Text>
                <Button my={2} onClick={() => inputRef.current?.click()}>
                  写真・動画を追加
                </Button>
                <Input
                  ref={inputRef}
                  type="file"
                  display="none"
                  multiple={true}
                  accept="image/jpeg,image/png,video/*"
                  onChange={(e) => handleFiles(e.target.files!)}
                />
                <Text fontSize="sm" color="gray.400" textAlign="center">
                  一度に最大20枚までアップロード可能です。
                  <br />
                  アップロードする画像サイズは250×250px以上、5MB以下にしてください。
                  <br />
                  動画の場合は25MB以下にしてください。
                  <br />
                  ※操作環境によって、処理に時間がかかる場合があります。
                </Text>
              </VStack>
              <SimpleGrid columns={10} gap={2}>
                {items.map(
                  (file, i) =>
                    !file.originalUrl && (
                      <EditingAssetItem
                        key={i}
                        type={file.type}
                        dataURL={file.displayUrl!}
                        tip={file.error}
                        onRemove={() => removeFile(i)}
                      />
                    ),
                )}
              </SimpleGrid>
            </VStack>
          </ModalBody>
          <ModalFooter as={Flex} gap={3}>
            <Button variant="secondary" onClick={handleCancel}>
              キャンセル
            </Button>
            <Button
              isDisabled={
                items.length === 0 ||
                isUploading ||
                items.some((item) => item.error)
              }
              onClick={handleOk}
            >
              決定
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

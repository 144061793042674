import { defineStyleConfig } from '@chakra-ui/react';

export const textareaStyle = defineStyleConfig({
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
  baseStyle: {
    borderRadius: 'md',
  },
  variants: {
    outline: {
      color: 'gray.500',
      bgColor: 'white',
      outline: 'none',
      resize: 'none',
      shadow: 'none',
      _hover: {
        borderColor: 'gray.200',
      },
      _placeholder: {
        color: 'gray.300',
      },
      _disabled: {
        bgColor: 'gray.50',
        color: 'gray.300',
        opacity: '1',
      },
      _invalid: {
        bgColor: 'red.100',
        borderColor: 'red.500',
        shadow: 'none',
        _focus: {
          borderColor: 'red.500',
          borderWidth: '2px',
        },
      },
      _focus: {
        borderColor: 'blue.500',
        borderWidth: '2px',
      },
      _focusVisible: {
        shadow: 'none',
      },
    },
  },
  sizes: {
    md: {
      h: 124,
      p: '10px 12px',
    },
  },
});

export const helpText = {
  base: {
    locale: {
      title: '言語',
      text: [
        '店舗情報を設定する言語を以下の2つから選択できます。',
        '・日本語',
        '・英語',
        '',
        'Apple Business Connectに連携したい',
        '・任意の言語を設定してください。',
        '※Apple Business Connectの仕様上、言語を指定しないと店舗情報を更新できません。',
        '',
        'Yahoo!プレイスに連携したい',
        '・「日本語」を選択してください。',
        '※Yahoo!プレイスの仕様上、日本語の場合以外は連携できません。',
      ],
    },
    name: {
      title: '店舗名',
      text: [
        '店舗名は必須入力項目です。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '',
        'Google ビジネスプロフィール連携の注意点',
        '・店舗名にプロモーション等を含めるとGBPのアカウントが停止されます。',
        '',
        'Apple Business Connect連携の注意点',
        '・Apple Business Connectで設定済みの「その他の店舗名」が上書きされます。',
      ],
    },
    brand: {
      title: 'ブランド',
      text: [
        'ブランド名は必須選択項目です。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Apple Business Connect',
        '',
        'Apple Business Connect連携の注意点',
        '・「優先する店舗名」に対して、ブランド名が更新されます。',
      ],
    },
    group: {
      title: 'グループ',
      text: [
        'この店舗を管理するためのグループを設定することができます。',
        'ひとつの店舗に複数のグループを設定できます。',
      ],
    },
    address: {
      title: '住所',
      text: [
        '郵便番号を含む住所を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '',
        '国または地域',
        '必須入力項目です。',
        '適切な値を選択してください。',
        '',
        '郵便番号',
        '郵便番号を入力してください。',
        '・日本の店舗：0000000で更新すると、店舗マスタには各媒体へは000-0000の形式で連携されます',
        '・海外の店舗：0000000のように数字のみ入力してください。',
        '',
        '都道府県または州',
        '必須入力項目です。',
        '適切な値を選択してください。',
        '',
        '市区町村',
        '必須入力項目です。',
        '適切な値を入力してください。',
        '',
        '町名または地名',
        '必須入力項目です。',
        '適切な値を入力してください。',
        '',
        '番地',
        '必須入力項目です。',
        '適切な値を入力してください。',
        '',
        '建物名',
        '建物名を登録できます。',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        '店舗の電話番号を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '・Facebook',
        '',
        'Facebook連携の注意点',
        '・店舗マスタの国名が「日本」の場合のみ連携されます。',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'それぞれの曜日ごと、祝日・祝前日の営業時間を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '・Facebook',
        '',
        '店舗マスタの入力時の注意点',
        '・それぞれの曜日ごとに、3つの営業時間まで登録できます。',
        '・1分単位で営業時間を登録できます。',
        '・日を跨いで営業時間を登録することができます。',
        '例：18:00-5:00のような入力が可能です。',
        '',
        'Yahoo!プレイス連携の注意点',
        '・祝日・祝前日の営業時間はYahoo!プレイスのみに連携されます。',
      ],
    },
    irregular: {
      title: '特別営業時間',
      text: [
        '特別営業時間を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
        '',
        'Yahoo!プレイス連携の注意点',
        '・特別営業時間を営業日として設定した場合、直近の10件が「特定営業日」として連携されます。',
        '・特別営業時間を休業日として設定した場合、直近の10件が「特定休業日」として連携されます。',
        '※休業する場合は特定休業日を設定する必要があります。',
      ],
    },
    establish: {
      title: '開業日',
      text: [
        '店舗の開業日を設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Yahoo!プレイス',
      ],
    },
    status: {
      title: '営業状況',
      text: [
        '店舗の営業中、休業、閉業のステータスを設定することができます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '',
        'Apple Business Connect連携の注意点',
        '・休業を選択すると、休業期間を設定できます。',
        '※休業開始日には、今日より前の日付を設定する必要があります。',
        '・閉業を選択すると、閉業日を設定できます。',
        '※閉業に切り替えると、店舗情報が更新できなくなります。',
      ],
    },
    instagram: {
      title: 'SNSやWEBサイトのリンク（Instagram）',
      text: [
        'Instagramのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
      ],
    },
    facebook: {
      title: 'SNSやWEBサイトのリンク（Facebook）',
      text: [
        'Facebookのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
      ],
    },
    twitter: {
      title: 'SNSやWEBサイトのリンク（X）',
      text: [
        'X（旧Twitter）のリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Apple Business Connect',
        '・Yahoo!プレイス',
      ],
    },
    youtube: {
      title: 'SNSやWEBサイトのリンク（Youtube）',
      text: [
        'Youtubeのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Yahoo!プレイス',
      ],
    },
    yelp: {
      title: 'SNSやWEBサイトのリンク（Yelp）',
      text: [
        'Yelpのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Apple Business Connect',
      ],
    },
    line: {
      title: 'SNSやWEBサイトのリンク（Line公式アカウント）',
      text: [
        'Line公式アカウントのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Yahoo!プレイス',
      ],
    },
    officialSite: {
      title: 'SNSやWEBサイトのリンク（公式サイト）',
      text: [
        '公式サイトのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール：ウェブサイトURL',
        '・Apple Business Connect：ホームページURL',
        '・Yahoo!プレイス：公式サイトURL',
      ],
    },
    bookingSite: {
      title: 'SNSやWEBサイトのリンク（予約サイト）',
      text: [
        '予約サイトのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
        '・Yahoo!プレイス',
      ],
    },
    shoppingSite: {
      title: 'SNSやWEBサイトのリンク（ECサイト）',
      text: [
        'ECサイトのリンクを任意で設定することができます。',
        '設定したSNSリンクは以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール：予約サイト',
        '・Yahoo!プレイス：通販サイト',
      ],
    },
    submitLink: {
      title: 'SNSやWEBサイトのリンク（投稿用リンク）',
      text: [
        '複数の店舗へ一括投稿する際に、ボタンの遷移先として設定可能なリンクを設定することができます。',
      ],
    },
    code: {
      title: '店番',
      text: [
        '店舗を識別するためのユニークな番号を設定できます。',
        '設定した内容は以下の媒体に自動で連携されます。',
        '・Google ビジネスプロフィール',
      ],
    },
  },
  google: {
    name: {
      title: 'ビジネス名',
      text: [
        'この店舗の正式名称を設定してください。',
        '例）居酒屋カンリー 目黒店',
        '※キャッチコピーやタグは入力しないでください。',
        '',
        '（店舗マスタの「店舗名」が反映されています。）',
      ],
    },
    mainCategory: {
      title: 'メインカテゴリ',
      text: [
        'この店舗に該当するカテゴリ（業態）を1つ選択してください。',
        '',
        '例）居酒屋、カフェ、ヘアサロン',
        '',
        'なお、一部のカテゴリではGoogleの仕様により、カンリーから「サービス」の登録ができません。',
        '登録の際にエラーが発生した場合は、GBPの管理画面から登録をお願いいたします。',
        '',
        '「サービス」をカンリーから登録できないカテゴリの例（一部抜粋）：',
        '倉庫、セルフストレージ施設、建材店、金物店、作業服店、工具レンタル業、雑貨店、ギフト雑貨ショップ、化粧品、文房具、調剤薬局、スーパーマーケット',
      ],
    },
    additionalCategory: {
      title: '追加カテゴリ',
      text: [
        'この店舗に該当するカテゴリ（業態）を1つ選択してください。',
        '',
        '例）居酒屋、カフェ、ヘアサロン',
        '',
        'なお、一部のカテゴリではGoogleの仕様により、カンリーから「サービス」の登録ができません。',
        '登録の際にエラーが発生した場合は、GBPの管理画面から登録をお願いいたします。',
        '',
        '「サービス」をカンリーから登録できないカテゴリの例（一部抜粋）：',
        '倉庫、セルフストレージ施設、建材店、金物店、作業服店、工具レンタル業、雑貨店、ギフト雑貨ショップ、化粧品、文房具、調剤薬局、スーパーマーケット',
      ],
    },
    address: {
      title: '住所',
      text: [
        'この店舗の住所を設定してください。',
        '（店舗マスタの「住所」が反映されています。）',
      ],
    },
    area: {
      title: 'サービス提供地域',
      text: [
        'この店舗で商品配達や出張型サービスを提供している場合のみ、その対象地域を選択してください。',
        '例）東京都、東京都目黒区、埼玉県さいたま市',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'この店舗の営業時間を設定してください。',
        '（店舗マスタの「営業時間」が反映されています。）',
      ],
    },
    hours: {
      title: '営業時間の詳細',
      text: [
        '宅配・テイクアウト・ドライブスルー・店舗受け取りなど、この店舗で提供している特定のサービスの営業時間を設定してください。',
      ],
    },
    businessDates: {
      title: '特別営業時間',
      text: [
        'この店舗で、営業時間が通常とは異なる日がある場合は設定してください。',
        '例）祝日、貸切がある日、定休日ではないが営業しない日',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        'この店舗の電話番号を設定してください。',
        '（店舗マスタの「電話番号」が反映されています。）',
      ],
    },
    website: {
      title: 'ウェブサイト',
      text: [
        'この店舗のウェブサイトURLを設定してください。',
        '例）https://jp.can-ly.com/',
        '※誘導したいサイトのURLでも設定可能です。',
      ],
    },
    snsLinks: {
      title: 'SNSリンク',
      text: [
        'この店舗のソーシャルメディアURLを設定してください。',
        '（Instagram・Facebook・X(旧Twitter)のリンクは、店舗マスタの各URLが反映されています。）',
      ],
    },
    placeActionLinks: {
      APPOINTMENT: {
        title: '予約',
        text: [
          'この店舗の予約ページURLを設定してください。',
          '',
          '例）自社HP・お店独自で制作したホームページの予約リンク',
        ],
      },
      ONLINE_APPOINTMENT: {
        title: 'オンライン診察',
        text: [
          'この店舗のオンライン診察ページURLを設定してください。',
          '',
          '例）自社ホームページ・お店独自で制作したホームページの予約リンク',
        ],
      },
      DINING_RESERVATION: {
        title: 'レストラン予約',
        text: [
          'この店舗の予約ページURLを設定してください。',
          '',
          '例）自社ホームページ・お店独自で制作したホームページの予約リンク',
        ],
      },
      FOOD_ORDERING: {
        title: 'デリバリー・テイクアウト',
        text: [
          'この店舗のデリバリー・テイクアウトページURLを設定してください。',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
      FOOD_DELIVERY: {
        title: 'デリバリー',
        text: [
          'この店舗のデリバリーページURLを設定してください。',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
      FOOD_TAKEOUT: {
        title: 'テイクアウト',
        text: [
          'この店舗のテイクアウトページURLを設定してください。',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
      SHOP_ONLINE: {
        title: 'オンラインショップ',
        text: [
          'この店舗のオンラインショップページURLを設定してください。',
          '',
          '例）自社ホームページ・お店独自で制作したホームページのリンク',
        ],
      },
    },
    attributes: {
      title: '属性',
      text: [
        'この店舗の特徴を設定してください。',
        '例）テラス席やWi-Fi の有無、決済方法など',
      ],
    },
    businessInfo: {
      title: 'ビジネス情報',
      text: [
        'この店舗の特徴や、この店舗で提供しているサービス・商品に関する情報を設定してください。',
        '例）京王井の頭線渋谷駅から徒歩1分、山手線渋谷駅から徒歩3分にある駅近のイタリアンレストランです。こじんまりとした落ち着いた空間で、大切なパートナーやご友人との時間をゆったりと過ごすことができます。',
        'イタリア産のボトルワインやビール、お酒が飲めない方には見た目もおしゃれなノンアルコールカクテルなど、豊富に取り揃えております。お酒と一緒に楽しめるお食事メニューのご用意もございます。',
        'お家に帰る前に、気の合う仲間や気さくなスタッフと素敵な時間を過ごしませんか？',
        'お気軽にご予約ください！心よりご来店お待ちしております。',
      ],
    },
    establishmentDate: {
      title: '開業日',
      text: ['この店舗の開業日を設定してください。'],
    },
    code: {
      title: '店舗コード',
      text: [
        'この店舗の「店舗コード」を設定してください。',
        '（店舗マスタの「店番」が反映されています。）',
      ],
    },
    status: {
      title: '営業状況',
      text: [
        'この店舗の営業状況を設定してください。',
        '（店舗マスタの「休業設定」が反映されています。）',
      ],
    },
    assetItems: {
      ADDITIONAL: {
        title: 'オーナー提供',
        text: ['Googleビジネスプロフィールのオーナーが追加した写真です。'],
      },
      CUSTOMERS: {
        title: 'ユーザー提供',
        text: [
          '実際に店舗を訪れたお客様が、クチコミなどでアップロードした写真です。',
        ],
      },
      COVER: {
        title: 'カバー',
        text: [
          '店舗のカバー写真です。店舗をもっともよく表す写真を1枚設定してください。',
          'Googleの仕様により、設定した写真が Google 上で最初に表示されない場合があります。',
        ],
      },
      PROFILE: {
        title: 'プロフィール',
        text: ['', ''],
      },
      LOGO: {
        title: 'ロゴ',
        text: [
          '店舗のロゴ写真です。',
          'お客様が店舗を見つけやすいように、ロゴ写真を1枚設定してください。',
        ],
      },
      EXTERIOR: {
        title: '外観',
        text: [
          '店舗の外観写真です。',
          'お客様が店舗を見つけやすいように、店舗の入口や周辺環境等の外観写真を設定してください。',
        ],
      },
      INTERIOR: {
        title: '店内',
        text: [
          '店舗の店内写真です。',
          '店舗の雰囲気がお客様に伝わるように、内装などの店内写真を設定してください。',
        ],
      },
      FOOD_AND_DRINK: {
        title: '食品と飲料',
        text: [
          '店舗の食べ物や飲み物の写真です。',
          'お客様が食事のプランを考えやすいように、食べ物や飲み物の写真を設定してください。',
        ],
      },
      PRODUCT: {
        title: '商品',
        text: [
          '店舗の商品写真です。',
          '扱っている商品がお客様に伝わるように、代表的な商品や人気の高い商品の写真を設定してください。',
        ],
      },
      MENU: {
        title: 'メニュー',
        text: [
          '店舗のメニュー写真です。',
          '提供しているメニューがお客様に伝わるように、代表的なメニューや人気の高いメニューの写真を設定してください。',
          'メニュー情報と一緒にアップロードした写真もこの項目に表示されます。',
        ],
      },
      TEAMS: {
        title: 'チーム',
        text: [
          '店舗スタッフの写真です。',
          'お客様にスタッフの人となりを感じてもらえるように、店舗スタッフの写真を設定してください。',
        ],
      },
      AT_WORK: {
        title: '職場',
        text: [
          '職場環境の写真です。',
          'お客様に職場の雰囲気が伝わるように、職場やオフィスの写真を設定してください。',
        ],
      },
      COMMON_AREA: {
        title: '共用エリア',
        text: [
          '共用エリアの写真です。',
          '出張やレジャーの計画を立てているお客様に雰囲気が伝わるように、温泉や朝食会場などの共用エリアの写真を設定してください。',
        ],
      },
      ROOMS: {
        title: '部屋',
        text: [
          '客室の写真です。',
          '出張やレジャーの計画を立てているお客様に雰囲気が伝わるように、さまざまなタイプの客室の写真を設定してください。',
        ],
      },
      CATEGORY_UNSPECIFIED: {
        title: 'オーナー提供',
        text: ['Googleビジネスプロフィールのオーナーが追加した写真です。'],
      },
    },
    menus: {
      title: 'メニュー',
      text: [
        '飲食ビジネスのカテゴリを含む店舗の場合、Googleビジネスプロフィールにメニューの一覧を表示できます。',
        '来店を検討しているお客様に向けて、店舗で提供している商品や料理などのメニュー情報を設定してください。',
      ],
    },
    services: {
      title: 'サービス',
      text: [
        '飲食ビジネス以外のカテゴリを含む店舗の場合、Googleビジネスプロフィールにサービスの一覧を表示できます。',
        '来店を検討しているお客様に向けて、店舗で提供しているサービス情報を設定してください。',
      ],
    },
  },
  facebook: {
    pageName: {
      title: 'ページ名',
      text: [
        'Facebookページの名前です。',
        'Facebook APIの仕様上、カンリーでは編集できません。',
      ],
    },
    userName: {
      title: 'ユーザーネーム',
      text: [
        'FacebookページのURL末尾として使用される文字列です。',
        'Facebook APIの仕様上、カンリーでは編集できません。',
      ],
    },
    about: {
      title: 'ページ情報',
      text: [
        'この店舗の説明を記載してください。',
        '例）東京都内に○店舗を展開しているカンリー目黒店の公式Facebookページです。',
      ],
    },
    impressum: {
      title: '所属者情報',
      text: [
        'この店舗を運営している会社名を記載してください。',
        '例）株式会社カンリー',
      ],
    },
    category: {
      title: 'カテゴリ',
      text: [
        'この店舗のカテゴリ（業態）を選択してください。',
        '例）居酒屋、カフェ、ヘアサロン',
        '',
        'Facebook APIの仕様上、カンリーからはカテゴリの複数設定ができません。（保存時にエラーにはなりませんが、Facebookには1つしか反映されません。）',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        'この店舗の電話番号です。',
        '店舗マスタの「電話番号」の情報が反映されています。',
        'なお、日本以外の電話番号は更新できません。',
      ],
    },
    website: {
      title: 'ウェブサイト',
      text: [
        'この店舗の公式ウェブサイトURLを記載してください。',
        '例）https://jp.can-ly.com/',
      ],
    },
    emails: {
      title: 'メールアドレス',
      text: ['この店舗で使用しているメールアドレスを記載してください。'],
    },
    priceRange: {
      title: '価格帯',
      text: ['この店舗における価格帯を選択してください。'],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'この店舗の営業時間です。',
        '店舗マスタの「営業時間」が反映されています。',
      ],
    },
    description: {
      title: 'その他の情報',
      text: ['この店舗に関するその他の情報を記載してください。'],
    },
  },
  instagram: {
    userName: {
      title: 'ユーザーネーム',
      text: [
        '「@」以降の英数字で設定する固有IDです。',
        'Instagram APIの仕様上、カンリーでは編集できません。',
      ],
    },
  },
  apple: {},
  yahoo: {
    placeId: {
      title: 'プレイスID',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '施設のYahoo!プレイスURL末尾を確認できます。（編集できません。）',
      ],
    },
    name: {
      title: '店舗名',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの店舗名を修正してください。',
      ],
    },
    ruby: {
      title: '店舗名フリガナ',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗名のフリガナを確認できます。',
      ],
    },
    category: {
      title: '業種カテゴリ',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗のカテゴリ（業種）を確認できます。',
        'メインカテゴリとサブカテゴリ合わせて、最大4つまで設定できます。',
      ],
    },
    tel: {
      title: '電話番号',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの電話番号を修正してください。',
      ],
    },
    latlng: {
      title: '緯度経度',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗の緯度経度を確認できます。',
      ],
    },
    address: {
      title: '住所',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの住所を修正してください。',
      ],
    },
    businessHours: {
      title: '営業時間',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの営業時間を修正してください。',
      ],
    },
    irregular: {
      title: '特別営業時間',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの特別営業時間を修正してください。',
        '・店舗マスタの特別営業時間を営業日として設定した場合、直近の10件が「特定営業日」として連携されます。',
        '・店舗マスタの特別営業時間を休業日として設定した場合、直近の10件が「特定休業日」として連携されます。',
      ],
    },
    businessHoursDescription: {
      title: '営業時間の説明',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '営業時間に関する特記事項等を入力できます。',
      ],
    },
    officialSite: {
      title: '公式サイトURL',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの公式サイトURLを修正してください。',
      ],
    },
    shoppingSite: {
      title: 'ECサイトURL',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタのECサイトリンクを修正してください。',
      ],
    },
    bookingSite: {
      title: '予約サイトURL',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの予約サイトリンクを修正してください。',
      ],
    },
    snsLinks: {
      tiktok: {
        title: 'SNSアカウントURL（Tiktok）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '店舗のTiktokアカウントURLを確認できます。',
        ],
      },
      other: {
        title: 'SNSアカウントURL（Tiktok以外）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '更新が必要な場合、店舗マスタのSNSまたはウェブサイトのリンクを修正してください。',
        ],
      },
    },
    payment: {
      title: '提供サービス（決済情報）',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗一覧の一括編集機能をご利用ください。',
      ],
    },
    attributes: {
      title: '提供サービス（その他）',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '店舗の設備やサービスを設定できます。',
        'カンリーからは更新ができません。お手数ですが、Yahoo!プレイスアカウントから更新してください。',
      ],
    },
    description: {
      title: '施設に関する説明文',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗一覧の一括編集機能をご利用ください。',
      ],
    },
    establish: {
      title: '開業日',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '更新が必要な場合、店舗マスタの開業日を修正してください。',
      ],
    },
    budget: {
      title: '平均予算（ランチ・ディナー）',
      text: [
        'Yahoo!プレイスに反映済みの情報を表示しています。',
        '',
        '飲食業の場合のみ利用いただけます。',
        'カンリーからは更新ができません。お手数ですが、Yahoo!プレイスアカウントから更新してください。',
      ],
    },
    assetItems: {
      COVER: {
        title: '店舗の写真（カバー）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・1枚のみ設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      SECONDARY: {
        title: '店舗の写真（製品・サービス）',
        text: [
          'Yahoo!プレイスに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      INSIDE: {
        title: '店舗の写真（内観）',
        text: [
          'Yahoo!プレイスアカウントに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      OUTSIDE: {
        title: '店舗の写真（外観）',
        text: [
          'Yahoo!プレイスアカウントに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
      ETC: {
        title: '店舗の写真（その他）',
        text: [
          'Yahoo!プレイスアカウントに反映済みの情報を表示しています。',
          '',
          '注意点',
          '・全カテゴリの写真含め最大200枚まで設定可能です。',
          '・登録可能なファイルタイプ：jpg、jpeg、gif、png',
          '・個別ファイルサイズ（容量）：10MB以下',
          '・個別ファイルサイズ；横720px以上、縦720px以上',
        ],
      },
    },
  },
  post: {
    stores: {
      title: '投稿する店舗/グループ',
      text: [
        '投稿したい店舗を選択してください。',
        '一度に複数の店舗に投稿できます。',
      ],
    },
    platform: {
      title: '投稿する媒体',
      text: ['投稿したい媒体を1つ選択してください。'],
    },
    now: {
      title: '現在時刻で投稿',
      text: ['「投稿」ボタンを押すと、すぐに投稿されます。'],
    },
    scheduleTime: {
      title: '投稿の予約',
      text: [
        '投稿したい日付と時刻を設定してください。',
        '設定された日時に投稿されます。',
      ],
    },
    google: {
      body: {
        title: '本文',
        text: ['1500字以内で入力してください。'],
      },
      media: {
        title: '写真',
        text: [
          '「写真を追加」枠内をクリックして、写真をアップロードしてください。',
          '写真は1投稿につき1枚アップロードできます。',
          '※動画は投稿できません。',
        ],
      },
      type: {
        title: '投稿タイプ',
        text: [
          '設定必須項目です。',
          '作成したい投稿タイプを選択してください。',
          'なお、Googleの仕様により、一部の店舗では「特典」が投稿できません（投稿実行時にエラーとなります）。',
        ],
      },
      title: {
        title: 'タイトル',
        text: ['58文字以内で入力してください。'],
      },
      period: {
        title: '期間',
        text: ['開始・終了日時を設定してください。'],
      },
      button: {
        title: 'ボタン',
        text: [
          '投稿にボタン（テキストリンク）を追加できます。',
          'ボタンを追加した場合は、ボタンのリンクも入力してください。',
          'なお「今すぐ電話」を選択した場合は、店舗マスタに設定されている電話番号が自動入力されます。',
        ],
      },
      buttonLink: {
        title: 'ボタンのリンク',
        text: [
          'ボタンを押したときに表示されるサイトのURLを入力してください。',
          '店舗マスタの「投稿用リンク」を使用することもできます。',
        ],
      },
      couponCode: {
        title: 'クーポンコード',
        text: ['58文字以内で入力してください。'],
      },
      couponUrl: {
        title: 'クーポンURL',
        text: [
          'ボタンを押したときに表示されるサイトのURLを入力してください。',
          '店舗マスタの「投稿用リンク」を使用することもできます。',
        ],
      },
      couponTerm: {
        title: 'クーポン利用規約',
        text: ['クーポンの利用規約を入力してください。'],
      },
    },
  },
};

import {
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  ModalBody,
  ModalBodyProps as ChakraModalBodyProps,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
  ModalContentProps,
} from '@chakra-ui/react';

export type ModalButtonsProps = {
  primary?: {
    label?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    onClick: () => void;
  };
  danger?: { label?: string; isDisabled?: boolean; onClick: () => void };
  secondary?: { label?: string; isDisabled?: boolean; onClick: () => void };
  text?: { label: string; isDisabled?: boolean; onClick: () => void };
};

type ModalBodyProps = Omit<ChakraModalBodyProps, 'children'>;

type ModalProps = Omit<ChakraModalProps, 'children'> & {
  title: string;
  message?: string;
  isOpen: boolean;
  showCloseButton?: boolean;
  buttons: ModalButtonsProps;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  modalContent?: ModalContentProps;
  modalBody?: ModalBodyProps;
};

export function Modal({
  title,
  message,
  isOpen,
  showCloseButton = false,
  onClose,
  buttons: { primary, danger, secondary, text },
  isDisabled = false,
  isLoading = false,
  children,
  modalContent,
  modalBody,
  ...rest
}: ModalProps) {
  return (
    <ChakraModal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent {...modalContent}>
        <ModalHeader>{title}</ModalHeader>
        {showCloseButton && <ModalCloseButton fontSize="md" />}
        <ModalBody {...modalBody}>
          {message && <Text whiteSpace="pre-wrap">{message}</Text>}
          {children}
        </ModalBody>
        <ModalFooter gap={3}>
          {text && (
            <Button
              variant="text"
              isDisabled={isDisabled || text.isDisabled}
              onClick={text.onClick}
            >
              {text.label}
            </Button>
          )}
          {secondary && (
            <Button
              variant="secondary"
              isDisabled={isDisabled || secondary.isDisabled}
              onClick={secondary.onClick}
            >
              {secondary.label || 'キャンセル'}
            </Button>
          )}
          {danger && (
            <Button
              variant="danger"
              isDisabled={isDisabled || danger.isDisabled}
              onClick={danger.onClick}
            >
              {danger.label || '削除'}
            </Button>
          )}
          {primary && (
            <Button
              isDisabled={isDisabled || primary.isDisabled}
              isLoading={isLoading || primary.isLoading}
              onClick={primary.onClick}
            >
              {primary.label || '決定'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
